<template>
  <Accordion>
    <AccordionTab :active="true" header="1. What is Omni?">
      <p>
        OMNI is a one-stop dashboard platform for all information regarding
        talent managers and their creators and the reports that support it.
      </p>
    </AccordionTab>
    <AccordionTab header="2. Why do I use Omni?">
      <p>
        If you are a talent manager and have data on your creator and their
        channels you will use OMNI to update this information.
      </p>
    </AccordionTab>
    <AccordionTab header="3. What are the roles in OMNI?">
      <p>There are different roles in OMNI. They are</p>
      <p>User (can view only)</p>
      <p>Manager (Talent Manager)</p>
      <p>Super Admin (same permssions as Manager with more edit permissions)</p>
      <p>Alpha (all rights)</p>
    </AccordionTab>
    <AccordionTab header="4. What is the Manage page?">
      <p>
        The Manage section of OMNI, holds all infomation on Managers and
        Creators. You can filter, add, edit quickly from here. Just click on
        expand to do those tasks.
      </p>
    </AccordionTab>
  </Accordion>
</template>
<script>
export default {
  name: "Introduction"
};
</script>
<style scoped></style>
